import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-08a158ec"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "pageList",
  class: "TtPayList"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_ListEngine = _resolveComponent("ListEngine")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ListEngine, {
      pageList: _ctx.pageList,
      ref: "pageListRef",
      formatPageInfo: _ctx.formatPageInfo
    }, {
      queryParams: _withCtx(() => [
        _createVNode(_component_el_row, { class: "myRow" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              span: 3,
              class: "myColTitle"
            }, {
              default: _withCtx(() => [
                _createTextVNode("客户：")
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 9 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  ref: el=>_ctx.refMap.set('customerId',el),
                  modelValue: _ctx.pageList.queryParam.customerId,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pageList.queryParam.customerId) = $event)),
                  placeholder: "请选择客户",
                  clearable: "",
                  style: {"width":"100%"}
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.comboSelect({comboId:'customerId'}), (item) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: item.value,
                        label: item.label,
                        value: item.value
                      }, null, 8, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, {
              span: 3,
              class: "myColTitle"
            }, {
              default: _withCtx(() => [
                _createTextVNode("币种：")
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 9 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  ref: el=>_ctx.refMap.set('bz',el),
                  modelValue: _ctx.pageList.queryParam.bz,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.pageList.queryParam.bz) = $event)),
                  placeholder: "请选择币种",
                  clearable: "",
                  style: {"width":"100%"}
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.comboSelect({comboId:'bz'}), (item) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: item.value,
                        label: item.label,
                        value: item.value
                      }, null, 8, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_row, { class: "myRow" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              span: 3,
              class: "myColTitle"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('ttPay.queryParam.type')) + "：", 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 9 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  ref: el=>_ctx.refMap.set('type',el),
                  modelValue: _ctx.pageList.queryParam.type,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.pageList.queryParam.type) = $event)),
                  placeholder: _ctx.$t('ttPay.queryParam.type_placeholder'),
                  clearable: "",
                  style: {"width":"100%"}
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.comboSelect({comboId:'type'}), (item) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: item.value,
                        label: item.label,
                        value: item.value
                      }, null, 8, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, {
              span: 3,
              class: "myColTitle"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('ttPay.queryParam.flag')) + "：", 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 9 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  ref: el=>_ctx.refMap.set('flag',el),
                  modelValue: _ctx.pageList.queryParam.flag,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.pageList.queryParam.flag) = $event)),
                  placeholder: _ctx.$t('ttPay.queryParam.flag_placeholder'),
                  clearable: "",
                  style: {"width":"100%"}
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.comboSelect({comboId:'flag'}), (item) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: item.value,
                        label: item.label,
                        value: item.value
                      }, null, 8, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      extendSlot: _withCtx(() => []),
      tbCols: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          prop: "F_NAME",
          label: "客户"
        }),
        _createVNode(_component_el_table_column, {
          prop: "F_TYPE",
          label: "用途"
        }),
        _createVNode(_component_el_table_column, {
          prop: "F_BZ",
          label: "币种"
        }),
        _createVNode(_component_el_table_column, {
          prop: "F_AMOUNT",
          label: "金额",
          "header-align": "center",
          align: "right"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("span", null, _toDisplayString(_ctx.utils.UtilPub.toAmountFormat(scope.row.F_AMOUNT,false,2)), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "F_FLAG",
          label: "是否提交"
        })
      ]),
      _: 1
    }, 8, ["pageList", "formatPageInfo"])
  ]))
}